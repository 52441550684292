import React, { Component } from 'react'
import Layout from "../components/layout"
import Seo from '../components/seo';
import '../sass/main.scss';

import {
     gtmTrackingDetailDataLayer
} from '../components/utilities';

class Page404 extends Component {

     componentDidMount() {
          if ($('#gatsby-focus-wrapper').attr('tabindex') == -1) {
               $('#gatsby-focus-wrapper').removeAttr('tabindex')
          }
          const brower = typeof window !== `undefined`;
          if (brower) {
               gtmTrackingDetailDataLayer('prePageview', { pageName: 'Page 404' })
          }
     }
     render() {
          return (
               <Layout>
                    <Seo
                         title='404 Page Not Found'
                         canonical=''
                         description='Page Not Found'
                         robots=''
                         image={null}
                    />
                    <div className="container">
                         <div className="page-not-found">
                              <h1>404</h1>
                              <h2>Page Not Found</h2>
                              <div className="back-to-home">
                                   <a href="/" className="button-white">Back To Home</a>
                              </div>
                         </div>
                    </div>
               </Layout>
          )
     }
}
export default Page404;
